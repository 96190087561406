import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Redirect "/" to "/jp" */}
          <Route path="/" element={<Navigate to="/jp" />} />
          {/* Define your routes */}
          <Route path="/jp" element={<div className="loader"></div>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
